<template>
  <div class="logistic-center-contacts-info">
    <div class="grey--text text--darken-1 body-2 mb-7">Contacts</div>

    <update-contact-info-modal
      :logistic-center-id="logisticCenterId"
      @create-logistic-center-contact="updateContacts"
      @update-logistic-center-contact="updateContacts"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          tile
          large
          color="light-blue darken-4"
          class="white--text mb-10"
          min-width="166"
          v-on="on"
        >
          Add contact
        </v-btn>
      </template>
    </update-contact-info-modal>

    <data-table
      hide-default-footer
      :headers="$options.contactListHeaders"
      :items="contacts"
      :loading="isLoading"
      show-expand
      single-expand
    >
      <template v-slot:item.name="{ item }">
        <update-contact-info-modal
          :contact="item"
          :logistic-center-id="logisticCenterId"
          edit-mode
          @create-logistic-center-contact="updateContacts"
          @update-logistic-center-contact="updateContacts"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="light-blue--text text--darken-4 font-weight-medium text-none px-0"
              text
              v-on="on"
            >
              {{ item.name }}
            </v-btn>
          </template>
        </update-contact-info-modal>
      </template>

      <template v-slot:item.data-table-expand="{ item }">
        <v-btn class="grey lighten-4" color="light-blue darken-4" icon @click="deleteContact(item)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </data-table>
  </div>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';
import UpdateContactInfoModal from '@/components/logistic-center/UpdateContactInfoModal.vue';

import {
  deleteLogisticCenterContact,
  getContactsListByLogisticCenterId
} from '@/api/logisticCenter.api';

import { getFormattedPhone } from '@/utils';

const CONTACT_LIST_HEADERS = [
  { text: 'Name', value: 'name' },
  { text: 'Email', value: 'email' },
  { text: 'Phone', value: 'formattedPhone' },
  { text: 'Comment', value: 'comment' },
  { text: '', value: 'data-table-expand' }
];

export default {
  name: 'LogisticCenterContactsInfo',
  components: { UpdateContactInfoModal, DataTable },
  contactListHeaders: CONTACT_LIST_HEADERS,
  props: {
    logisticCenterId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      contacts: [],
      isLoading: false
    };
  },
  mounted() {
    this.updateContacts();
  },
  methods: {
    async getContacts() {
      this.isLoading = true;

      try {
        const { data } = await getContactsListByLogisticCenterId(this.logisticCenterId);

        return this.transformContactsData(data);
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    },
    transformContactsData(contacts) {
      return contacts.map(contact => {
        const { phoneIso: isoCode, phone: phoneNumber } = contact;

        return { ...contact, formattedPhone: getFormattedPhone({ isoCode, phoneNumber }) };
      });
    },
    async updateContacts() {
      this.contacts = await this.getContacts();
    },
    async deleteContact({ id: contactId }) {
      await deleteLogisticCenterContact({ logisticCenterId: this.logisticCenterId, contactId });
      this.updateContacts();
    }
  }
};
</script>
