<template>
  <section class="pt-10">
    <v-btn
      tile
      large
      :loading="isFileUploading"
      color="light-blue darken-4"
      class="white--text mr-12 mb-10"
      @click="selectStockFile"
    >
      Upload stocks
    </v-btn>

    <data-table
      :headers="$options.productsHeaderConfig"
      :items="logisticCenterProducts"
      :items-per-page="50"
      :loading="isLoading"
      :disable-pagination="isLoading"
    >
      <template v-slot:item.name="{ item }">
        <link-with-icon :icon-url="getIconUrl(item)" :link-text="item.name" :link-to="{}" />
      </template>

      <template v-slot:item.sku="{ item }">
        <span v-if="item.sku">{{ item.sku }}</span>
        <span v-else class="blue-grey--text text--lighten-2">n/a</span>
      </template>

      <template v-slot:item.quantity="{ item }">
        <update-product-quantity-info-modal :product="item" @update-product="getProductsList">
          <template v-slot:activator="{ on }">
            <span
              v-if="item.quantity"
              class="light-blue--text text--darken-4 font-weight-medium cursor-pointer"
              v-on="on"
            >
              {{ item.quantity }}
            </span>
            <span v-else class="blue-grey--text text--lighten-2 cursor-pointer" v-on="on">n/a</span>
          </template>
        </update-product-quantity-info-modal>
      </template>

      <template v-slot:item.updated="{ item }">
        <span v-if="item.updated">{{ item.updated }}</span>
        <span v-else class="blue-grey--text text--lighten-2">n/a</span>
      </template>
    </data-table>

    <information-modal
      v-model="isInfoModalVisible"
      width="420"
      :main-content="mainInfoContent"
      :error-content="errorInfoContent"
      @close-modal="closeInfoModal"
    />
  </section>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';
import LinkWithIcon from '@/components/common/LinkWithIcon.vue';
import UpdateProductQuantityInfoModal from '@/components/logistic-center/UpdateProductQuantityInfoModal.vue';
import InformationModal from '@/components/common/InformationModal.vue';

import { getLogisticCenterProducts, uploadLogisticCenterStock } from '@/api/logisticCenter.api';
import { formatDateFieldsInArray, isXlsxFile } from '@/utils';

const PRODUCTS_HEADER_CONFIG = [
  { text: 'Product name', value: 'name' },
  { text: 'SKU', value: 'sku' },
  { text: 'Quantity', value: 'quantity' },
  { text: 'Updated', value: 'updated' }
];

const ICON_URL = {
  PRODUCT: '/icons/product-icon.svg',
  INGREDIENT: '/icons/ingredient-icon.svg'
};

const PRODUCT_CATEGORY = {
  INGREDIENT: 'Ingredient'
};

export default {
  name: 'ProductsTab',
  components: { InformationModal, UpdateProductQuantityInfoModal, DataTable, LinkWithIcon },
  productsHeaderConfig: PRODUCTS_HEADER_CONFIG,
  props: {
    logisticCenter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isInfoModalVisible: false,
      mainInfoContent: 'Stock was updated',
      errorInfoContent: '',
      isLoading: false,
      isFileUploading: false,
      stockFileInput: null,
      logisticCenterProducts: []
    };
  },
  mounted() {
    this.getProductsList();
    this.initFileInput();
  },
  methods: {
    getIconUrl({ category }) {
      const isIngredient = category === PRODUCT_CATEGORY.INGREDIENT;
      return isIngredient ? ICON_URL.INGREDIENT : ICON_URL.PRODUCT;
    },
    async getProductsList() {
      this.isLoading = true;
      try {
        const { data: products } = await getLogisticCenterProducts(this.logisticCenter.id);

        this.logisticCenterProducts = formatDateFieldsInArray(products, ['updated']);
      } catch (error) {
        this.logisticCenterProducts = [];
      } finally {
        this.isLoading = false;
      }
    },
    selectStockFile() {
      this.stockFileInput.click();
    },
    initFileInput() {
      const fileInput = document.createElement('input');

      fileInput.type = 'file';
      fileInput.addEventListener('change', ({ target: { files } }) => {
        this.uploadFiles(files);
      });

      this.stockFileInput = fileInput;
    },
    async uploadFiles(files) {
      if (!files || files.length === 0) {
        return;
      }

      const [file] = files;

      if (!isXlsxFile(file)) {
        return;
      }

      this.isFileUploading = true;

      const stockErrors = await this.uploadStock(file);

      this.isFileUploading = false;

      this.errorInfoContent = this.transformStockErrors(stockErrors);
      this.isInfoModalVisible = true;

      await this.getProductsList();
    },
    transformStockErrors(stockErrors) {
      const errorNumber = Object.keys(stockErrors).length;

      if (!errorNumber) {
        return '';
      }

      const errorsHeaderText = `${errorNumber} errors detected:\n`;

      return Object.entries(stockErrors).reduce((errorsText, [rowNumber, rowError]) => {
        return `${errorsText}\n${rowNumber} - ${rowError}`;
      }, errorsHeaderText);
    },
    async uploadStock(file) {
      const { data: stockErrors } = await uploadLogisticCenterStock({
        id: this.logisticCenter.id,
        file
      });

      this.resetStockFileInput();

      return stockErrors;
    },
    resetStockFileInput() {
      this.stockFileInput.value = null;
    },
    closeInfoModal() {
      this.isInfoModalVisible = false;
    }
  }
};
</script>
