<template>
  <update-info-modal
    v-model="isQuantityInfoModalShown"
    width="420"
    action-button-label="Update"
    :loading="isLoading"
    modal-title="Edit"
    :action-button-color="actionButtonColor"
    @close-modal="onModalClose"
    @action-button-click="onActionButtonClick"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <template v-slot:modal-body>
      <v-form ref="productQuantityInfoForm" :key="isQuantityInfoModalShown" class="pt-1">
        <base-text-field
          v-model="productInfo.quantity"
          label="Product quantity"
          :rules="$options.validationFieldRequiredRule"
        />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import { clone, omit } from 'ramda';

import BaseTextField from '@/components/common/BaseTextField.vue';
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';

import { updateLogisticCenterProduct } from '@/api/logisticCenter.api';

import { DEFAULT_BUTTON_COLOR, ERROR_BUTTON_COLOR, VALIDATION_RULES } from '@/constants/common';

export default {
  name: 'UpdateProductQuantityInfoModal',
  components: { BaseTextField, UpdateInfoModal },
  validationFieldRequiredRule: [VALIDATION_RULES.REQUIRED],
  props: {
    product: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      productInfo: {},
      actionButtonColor: DEFAULT_BUTTON_COLOR,
      isQuantityInfoModalShown: false,
      isLoading: false
    };
  },
  watch: {
    product: {
      handler: 'getProductInfo',
      immediate: true
    }
  },
  methods: {
    onModalClose() {
      this.isQuantityInfoModalShown = false;

      if (this.product.quantity !== this.productInfo.quantity) {
        this.getProductInfo();
      }
    },
    getProductInfo() {
      this.productInfo = clone(this.product);
    },
    async onActionButtonClick() {
      if (!this.$refs.productQuantityInfoForm.validate()) {
        return;
      }

      this.isLoading = true;
      this.actionButtonColor = DEFAULT_BUTTON_COLOR;

      try {
        const product = omit(['updated'], this.productInfo);

        await updateLogisticCenterProduct(product);

        this.$emit('update-product');
        this.onModalClose();
      } catch (error) {
        this.actionButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
