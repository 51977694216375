<template>
  <section class="pt-10">
    <ul>
      <li v-for="{ code, label } of countries" :key="code" class="d-flex justify-space-between">
        <span class="light-blue--text font-weight-medium text--darken-4">{{ label }}</span>

        <v-btn
          v-if="isSaudiArabia(code)"
          class="ml-8"
          :color="saudiCitiesUploadButtonColor"
          :loading="isLoadingSaudiCities"
          @click="uploadSaudiArabiaCities"
        >
          <span class="details-photo-controls__button-text">
            Upload cities
          </span>
        </v-btn>
      </li>
    </ul>

    <input
      ref="saudiArabiaCitiesFileInput"
      class="d-none"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      @change="onUploadSaudiArabiaActiveCities"
    />
  </section>
</template>

<script>
import { uploadSaudiArabiaActiveCities } from '@/api/countries.api';

import { DELIVERY_FLOW } from '@/constants/regions';
import { COUNTRY_ISO } from '@/constants/countries';

export default {
  name: 'CountriesTab',
  props: {
    logisticCenter: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoadingSaudiCities: false,
      hasSaudiCityUploadFailed: false
    };
  },
  computed: {
    countries() {
      if (this.logisticCenter.deliveryFlow !== DELIVERY_FLOW.AL_MAJDOUIE) {
        return [];
      }

      return [{ code: COUNTRY_ISO.SAUDI_ARABIA, label: 'Saudi Arabia' }];
    },
    saudiCitiesUploadButtonColor() {
      return this.hasSaudiCityUploadFailed ? 'error' : 'primary';
    }
  },
  methods: {
    isSaudiArabia(countryCode) {
      return countryCode === COUNTRY_ISO.SAUDI_ARABIA;
    },
    uploadSaudiArabiaCities() {
      const { saudiArabiaCitiesFileInput } = this.$refs;

      saudiArabiaCitiesFileInput.click();
    },
    async onUploadSaudiArabiaActiveCities({ target: { files } }) {
      if (!files || files.length === 0) {
        return;
      }

      const [file] = files;

      try {
        this.isLoadingSaudiCities = true;

        await uploadSaudiArabiaActiveCities(file);

        this.hasSaudiCityUploadFailed = false;
      } catch (error) {
        this.hasSaudiCityUploadFailed = true;
      } finally {
        this.isLoadingSaudiCities = false;
        this.$refs.saudiArabiaCitiesFileInput.value = null;
      }
    }
  }
};
</script>
