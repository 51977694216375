var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"delivery-costs-tab pt-10"},[_c('update-region-info-modal',{attrs:{"logistic-center":_vm.logisticCenter,"available-countries":_vm.availableCountries},on:{"update-region":_vm.updateCountries},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text mb-10",attrs:{"tile":"","large":"","color":"light-blue darken-4"}},on),[_vm._v(" ADD COUNTRY ")])]}}])}),_c('data-table',{attrs:{"headers":_vm.$options.deliveryCostsHeaderConfig,"items":_vm.logisticCenterCountries,"hide-default-footer":true,"disable-pagination":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('update-region-info-modal',{attrs:{"country":item,"logistic-center":_vm.logisticCenter,"edit-mode":""},on:{"update-region":_vm.updateCountries},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"light-blue--text text--darken-4 font-weight-medium text-none px-0",attrs:{"text":""}},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})]}},_vm._l((_vm.$options.deliveryCostsFields),function(fieldName){return {key:("item." + fieldName),fn:function(ref){
var item = ref.item;
return [_c('div',{key:fieldName},[(item[fieldName])?_c('span',[_vm._v(_vm._s(item[fieldName]))]):_c('span',{staticClass:"blue-grey--text text--lighten-2"},[_vm._v("n/a")])])]}}}),{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"grey lighten-4",attrs:{"color":"light-blue darken-4","icon":""},on:{"click":function($event){return _vm.deleteCountry(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }