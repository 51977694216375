var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-10"},[_c('v-btn',{staticClass:"white--text mr-12 mb-10",attrs:{"tile":"","large":"","loading":_vm.isFileUploading,"color":"light-blue darken-4"},on:{"click":_vm.selectStockFile}},[_vm._v(" Upload stocks ")]),_c('data-table',{attrs:{"headers":_vm.$options.productsHeaderConfig,"items":_vm.logisticCenterProducts,"items-per-page":50,"loading":_vm.isLoading,"disable-pagination":_vm.isLoading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('link-with-icon',{attrs:{"icon-url":_vm.getIconUrl(item),"link-text":item.name,"link-to":{}}})]}},{key:"item.sku",fn:function(ref){
var item = ref.item;
return [(item.sku)?_c('span',[_vm._v(_vm._s(item.sku))]):_c('span',{staticClass:"blue-grey--text text--lighten-2"},[_vm._v("n/a")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('update-product-quantity-info-modal',{attrs:{"product":item},on:{"update-product":_vm.getProductsList},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.quantity)?_c('span',_vm._g({staticClass:"light-blue--text text--darken-4 font-weight-medium cursor-pointer"},on),[_vm._v(" "+_vm._s(item.quantity)+" ")]):_c('span',_vm._g({staticClass:"blue-grey--text text--lighten-2 cursor-pointer"},on),[_vm._v("n/a")])]}}],null,true)})]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [(item.updated)?_c('span',[_vm._v(_vm._s(item.updated))]):_c('span',{staticClass:"blue-grey--text text--lighten-2"},[_vm._v("n/a")])]}}])}),_c('information-modal',{attrs:{"width":"420","main-content":_vm.mainInfoContent,"error-content":_vm.errorInfoContent},on:{"close-modal":_vm.closeInfoModal},model:{value:(_vm.isInfoModalVisible),callback:function ($$v) {_vm.isInfoModalVisible=$$v},expression:"isInfoModalVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }