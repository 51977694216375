<template>
  <v-textarea
    :rows="3"
    outlined
    dense
    no-resize
    auto-grow
    hide-details="auto"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'BaseTextarea'
};
</script>
