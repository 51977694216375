import axios from 'axios';

import httpClient from '@/api/httpClient';

import { ORDERS_ENDPOINT } from '@/constants/endpoints';
import LocalStorageService from '@/services/LocalStorageService';

const getOrdersList = params => httpClient.post(`${ORDERS_ENDPOINT}/findPaged`, params);

const getOrderById = orderId => httpClient.get(`${ORDERS_ENDPOINT}/${orderId}/details`);

const cancelOrder = ({ comment, file, orderId }) => {
  const formData = new FormData();
  formData.append('file', file);
  const searchParams = new URLSearchParams({ comment });
  const url = `${ORDERS_ENDPOINT}/${orderId}/cancel?${searchParams}`;

  return httpClient.post(url, formData, { 'content-Type': 'multipart/form-data' });
};

const resendOrder = order =>
  httpClient.post('/api/adminPanel/delayedOrder/prepareForResend', order);

const downloadCancelledOrderAttachment = orderId => {
  const token = LocalStorageService.getToken();

  return axios({
    baseURL: process.env.VUE_APP_BASE_URL,
    url: `${ORDERS_ENDPOINT}/${orderId}/cancelAttachment`,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

const uploadCancelledOrderAttachment = ({ orderId, file }) => {
  const formData = new FormData();
  formData.append('file', file);

  return httpClient.post(`${ORDERS_ENDPOINT}/${orderId}/cancelAttachment`, formData, {
    'content-Type': 'multipart/form-data'
  });
};

const deleteCancelledOrderAttachment = orderId =>
  httpClient.delete(`${ORDERS_ENDPOINT}/${orderId}/cancelAttachment`);

const getCarriersByOrderId = orderId => httpClient.get(`${ORDERS_ENDPOINT}/${orderId}/carriers`);

const updateOrder = (orderId, order) =>
  httpClient.post(`${ORDERS_ENDPOINT}/${orderId}/update`, order);

const orderForceDelivery = ({ orderId }) =>
  httpClient.post(`/api/adminPanel/order/${orderId}/forceDelivery`);

const markAsTestOrder = (orderId, isTest) => {
  const params = new URLSearchParams({ testOrder: isTest });

  return httpClient.patch(`/api/adminPanel/orders/${orderId}/testStatus?${params}`);
};

export {
  getOrdersList,
  cancelOrder,
  resendOrder,
  getOrderById,
  downloadCancelledOrderAttachment,
  uploadCancelledOrderAttachment,
  deleteCancelledOrderAttachment,
  getCarriersByOrderId,
  updateOrder,
  orderForceDelivery,
  markAsTestOrder
};
