<template>
  <div class="d-flex justify-space-between">
    <div
      v-for="{ fields, columnName, columnWidth } in columns"
      :key="columnName"
      class="mr-2"
      :style="{ width: columnWidth }"
    >
      <div v-for="{ fieldLabel, fieldName } in fields" :key="fieldLabel" class="mb-5">
        <slot :name="`${columnName}.${fieldName}.label`">
          <div class="grey--text text--darken-1 body-2">{{ fieldLabel }}</div>
        </slot>
        <slot :name="`${columnName}.${fieldName}.content`">
          <div v-if="!isNone(details[fieldName])" class="grey--text text--darken-3 body-1">
            {{ details[fieldName] }}
          </div>
          <div v-else class="grey--text text--lighten-1 font-italic body-1">none</div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailsList',
  props: {
    details: {
      type: Object,
      required: true
    },
    columns: {
      type: Array,
      required: true
    }
  },
  methods: {
    isNone(value) {
      return !value || value === 'null';
    }
  }
};
</script>
