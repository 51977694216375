<template>
  <section class="delivery-costs-tab pt-10">
    <update-region-info-modal
      :logistic-center="logisticCenter"
      :available-countries="availableCountries"
      @update-region="updateCountries"
    >
      <template v-slot:activator="{ on }">
        <v-btn tile large color="light-blue darken-4" class="white--text mb-10" v-on="on">
          ADD COUNTRY
        </v-btn>
      </template>
    </update-region-info-modal>

    <data-table
      :headers="$options.deliveryCostsHeaderConfig"
      :items="logisticCenterCountries"
      :hide-default-footer="true"
      disable-pagination
    >
      <template v-slot:item.name="{ item }">
        <!--span class="light-blue--text text--darken-4 font-weight-medium">{{ item.name }}</span!-->
        <update-region-info-modal
          :country="item"
          :logistic-center="logisticCenter"
          edit-mode
          @update-region="updateCountries"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="light-blue--text text--darken-4 font-weight-medium text-none px-0"
              text
              v-on="on"
            >
              {{ item.name }}
            </v-btn>
          </template>
        </update-region-info-modal>
      </template>

      <template
        v-for="fieldName in $options.deliveryCostsFields"
        v-slot:[`item.${fieldName}`]="{ item }"
      >
        <div :key="fieldName">
          <span v-if="item[fieldName]">{{ item[fieldName] }}</span>
          <span v-else class="blue-grey--text text--lighten-2">n/a</span>
        </div>
      </template>

      <template v-slot:item.data-table-expand="{ item }">
        <v-btn class="grey lighten-4" color="light-blue darken-4" icon @click="deleteCountry(item)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </data-table>
  </section>
</template>

<script>
import { differenceWith } from 'ramda';

import DataTable from '@/components/common/DataTable.vue';
import UpdateRegionInfoModal from '@/components/logistic-center/UpdateRegionInfoModal.vue';

import {
  getAllCountries,
  updateLogisticCenter,
  getLogisticCenterDeliveryCostsById
} from '@/api/logisticCenter.api';

const DELIVERY_FIELD_NAME = {
  VAT: 'vat',
  COST_WITH_SERUM_CLASSIC: 'classicDeliveryCostWithSerum',
  COST_WITH_SERUM_EXPRESS: 'expressDeliveryCostWithSerum',
  COST_NO_SERUM_CLASSIC: 'classicDeliveryCostNoSerum',
  COST_NO_SERUM_EXPRESS: 'expressDeliveryCostNoSerum',
  DELIVERY_TIME_CLASSIC: 'classicDeliveryDays',
  DELIVERY_TIME_EXPRESS: 'expressDeliveryDays'
};

const DELIVERY_COSTS_FIELDS = Object.values(DELIVERY_FIELD_NAME);

const DELIVERY_COSTS_HEADER_CONFIG = [
  { text: 'Country', value: 'name' },
  { text: 'VAT', value: 'vat' },
  {
    text: 'Total Costs (w/serum) Classic',
    value: DELIVERY_FIELD_NAME.COST_WITH_SERUM_CLASSIC
  },
  {
    text: 'Total Costs (w/serum) Express',
    value: DELIVERY_FIELD_NAME.COST_WITH_SERUM_EXPRESS
  },
  {
    text: 'Total Costs (no serum) Classic',
    value: DELIVERY_FIELD_NAME.COST_NO_SERUM_CLASSIC
  },
  {
    text: 'Total Costs (no serum) Express',
    value: DELIVERY_FIELD_NAME.COST_NO_SERUM_EXPRESS
  },
  {
    text: 'Delivery Time Classic',
    value: DELIVERY_FIELD_NAME.DELIVERY_TIME_CLASSIC
  },
  {
    text: 'Delivery Time Express',
    value: DELIVERY_FIELD_NAME.DELIVERY_TIME_EXPRESS
  },
  { text: '', value: 'data-table-expand' }
];

export default {
  name: 'DeliveryCostsTab',
  components: { DataTable, UpdateRegionInfoModal },
  deliveryCostsHeaderConfig: DELIVERY_COSTS_HEADER_CONFIG,
  deliveryCostsFields: DELIVERY_COSTS_FIELDS,
  props: {
    logisticCenter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      availableCountries: [],
      allCountries: [],
      logisticCenterCountries: []
    };
  },
  watch: {
    logisticCenter: {
      handler: 'setAvailableCountries',
      deep: true
    }
  },
  mounted() {
    this.setCountries();
  },
  methods: {
    async setCountries() {
      this.allCountries = await this.getAllCountries();
      this.logisticCenterCountries = await this.getLogisticCenterCountries();
      this.availableCountries = this.getAvailableCountries();
    },
    async getAllCountries() {
      const { data } = await getAllCountries();
      return data;
    },
    async getLogisticCenterCountries() {
      const { data } = await getLogisticCenterDeliveryCostsById(this.logisticCenter.id);

      const countryNameToPricesMap = data.reduce(
        (countriesMap, countryData) => ({ ...countriesMap, [countryData.country]: countryData }),
        {}
      );

      return this.logisticCenter.regions.map(country => ({
        ...country,
        ...countryNameToPricesMap[country.name]
      }));
    },
    getAvailableCountries() {
      return differenceWith(
        (country, alreadyAddedCountry) => country.id === alreadyAddedCountry.id,
        this.allCountries,
        this.logisticCenterCountries
      );
    },
    updateCountries() {
      this.$emit('update-countries');
    },
    async deleteCountry(country) {
      const regions = this.logisticCenter.regions.filter(({ id }) => id !== country.id);

      await updateLogisticCenter({ ...this.logisticCenter, regions });
      this.updateCountries();
      await this.setCountries();
    }
  }
};
</script>
