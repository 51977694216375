<template>
  <v-dialog persistent v-bind="$attrs" v-on="$listeners">
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <v-card class="d-flex flex-column">
      <v-card-title v-if="modalTitle" class="mt-8">
        <h1 class="title font-weight-regular">{{ modalTitle }}</h1>
      </v-card-title>

      <v-card-text class="info-modal__content d-flex flex-column mt-10">
        <div class="info-modal__main-content">{{ mainContent }}</div>
        <v-divider v-if="errorContent" class="mt-9 mb-5"/>
        <div class="info-modal__error-content error--text">{{ errorContent }}</div>
      </v-card-text>

      <v-card-actions class="d-flex justify-center my-5">
        <v-btn color="light-blue darken-4" text @click="closeModal">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InformationModal',
  props: {
    modalTitle: {
      type: String,
      default: ''
    },
    mainContent: {
      type: String,
      default: ''
    },
    errorContent: {
      type: String,
      default: ''
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
.info-modal {
  &__content {
    max-height: 710px;
    overflow-y: auto;
    scrollbar-width: thin;
    font-size: 14px;
  }

  &__main-content,
  &__error-content {
    color: black;
    text-align: center;
    white-space: pre-wrap;
  }
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
}
</style>
