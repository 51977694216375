<template>
  <section class="pt-10">
    <data-table :headers="$options.carriersHeaderConfig" :items="carriers" hide-default-footer>
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
    </data-table>
  </section>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';

import { getCarriers } from '@/api/logisticCenter.api';

const CARRIERS_HEADER_CONFIG = [{ text: 'Name', value: 'name' }];

export default {
  name: 'CarriersTab',
  components: { DataTable },
  carriersHeaderConfig: CARRIERS_HEADER_CONFIG,
  props: {
    logisticCenter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      carriers: [],
      isCarrierModalOpen: false
    };
  },
  mounted() {
    this.getCarriersList();
  },
  methods: {
    async getCarriersList() {
      const { data } = await getCarriers();

      this.carriers = data;
    }
  }
};
</script>
