var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logistic-center-contacts-info"},[_c('div',{staticClass:"grey--text text--darken-1 body-2 mb-7"},[_vm._v("Contacts")]),_c('update-contact-info-modal',{attrs:{"logistic-center-id":_vm.logisticCenterId},on:{"create-logistic-center-contact":_vm.updateContacts,"update-logistic-center-contact":_vm.updateContacts},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"white--text mb-10",attrs:{"tile":"","large":"","color":"light-blue darken-4","min-width":"166"}},on),[_vm._v(" Add contact ")])]}}])}),_c('data-table',{attrs:{"hide-default-footer":"","headers":_vm.$options.contactListHeaders,"items":_vm.contacts,"loading":_vm.isLoading,"show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('update-contact-info-modal',{attrs:{"contact":item,"logistic-center-id":_vm.logisticCenterId,"edit-mode":""},on:{"create-logistic-center-contact":_vm.updateContacts,"update-logistic-center-contact":_vm.updateContacts},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"light-blue--text text--darken-4 font-weight-medium text-none px-0",attrs:{"text":""}},on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"grey lighten-4",attrs:{"color":"light-blue darken-4","icon":""},on:{"click":function($event){return _vm.deleteContact(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }