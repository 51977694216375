<template>
  <div class="d-flex align-center">
    <slot name="icon">
      <img v-if="iconUrl" class="mr-2" :src="iconUrl" width="24" height="24" alt="Icon" />
    </slot>
    <slot name="text">
      <router-link
        class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
        :to="linkTo"
      >
        {{ linkText }}
      </router-link>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'LinkWithIcon',
  props: {
    iconUrl: {
      type: String,
      default: ''
    },
    linkText: {
      type: [Number, String],
      default: ''
    },
    linkTo: {
      type: Object,
      default: () => ({})
    }
  }
};
</script>
