<template>
  <update-info-modal
    v-model="isContactModalShown"
    width="420"
    scrollable
    action-button-label="Save"
    :loading="isLoading"
    :modal-title="contactModalTitle"
    :action-button-color="actionButtonColor"
    @close-modal="onModalClose"
    @action-button-click="onActionButtonClick"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <template v-slot:modal-body>
      <v-form ref="contactInfoForm" :key="isContactModalShown" class="pt-1">
        <base-text-field
          v-model="contactInfo.name"
          class="mb-5"
          label="Name *"
          :rules="requiredFieldRules"
        />
        <base-text-field
          v-model="contactInfo.email"
          class="mb-5"
          label="Email *"
          :rules="requiredFieldRules"
        />

        <phone-number-input
          v-model="contactInfo.phone"
          class="mb-5"
          :default-country="contactInfo.phoneIso"
          placeholder="Phone"
          @country-changed="updateContactPhoneIso"
        />

        <base-textarea v-model="contactInfo.comment" class="mb-5" label="Comment" />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import { omit } from 'ramda';

import BaseTextarea from '@/components/common/BaseTextarea.vue';
import BaseTextField from '@/components/common/BaseTextField.vue';
import PhoneNumberInput from '@/components/common/PhoneNumberInput.vue';
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';

import { createLogisticCenterContact, updateLogisticCenterContact } from '@/api/logisticCenter.api';

import { DEFAULT_BUTTON_COLOR, ERROR_BUTTON_COLOR, VALIDATION_RULES } from '@/constants/common';

const CONTACT_FIELDS_TO_OMIT = ['formattedPhone'];
const DEFAULT_CONTACT = {
  name: null,
  email: null,
  phone: null,
  phoneIso: null,
  comment: null
};

export default {
  name: 'UpdateContactInfoModal',
  components: { BaseTextarea, PhoneNumberInput, BaseTextField, UpdateInfoModal },
  props: {
    contact: {
      type: Object,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    logisticCenterId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      contactInfo: { ...DEFAULT_CONTACT },
      actionButtonColor: DEFAULT_BUTTON_COLOR,
      isContactModalShown: false,
      requiredFieldRules: [VALIDATION_RULES.REQUIRED],
      isLoading: false
    };
  },
  computed: {
    contactModalTitle() {
      const titlePrefix = this.editMode ? 'Edit' : 'Add';
      return `${titlePrefix} contact`;
    }
  },
  mounted() {
    this.getInfoFromContact();
  },
  methods: {
    getInfoFromContact() {
      this.contactInfo = omit(CONTACT_FIELDS_TO_OMIT, this.contact);
    },
    onModalClose() {
      this.isContactModalShown = false;

      this.getInfoFromContact();
    },
    async onActionButtonClick() {
      if (!this.$refs.contactInfoForm.validate()) {
        return;
      }

      this.isLoading = true;
      this.actionButtonColor = DEFAULT_BUTTON_COLOR;

      const contact = { ...this.contactInfo, logisticCenterId: this.logisticCenterId };

      try {
        if (this.editMode) {
          await updateLogisticCenterContact(this.logisticCenterId, contact);
          this.onModalClose();
          this.$emit('update-logistic-center-contact');

          return;
        }

        await createLogisticCenterContact(this.logisticCenterId, contact);
        this.onModalClose();
        this.$emit('create-logistic-center-contact');
      } catch (error) {
        this.actionButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    },
    updateContactPhoneIso({ iso2 }) {
      this.contactInfo.phoneIso = iso2;
    }
  }
};
</script>
