var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-space-between"},_vm._l((_vm.columns),function(ref){
var fields = ref.fields;
var columnName = ref.columnName;
var columnWidth = ref.columnWidth;
return _c('div',{key:columnName,staticClass:"mr-2",style:({ width: columnWidth })},_vm._l((fields),function(ref){
var fieldLabel = ref.fieldLabel;
var fieldName = ref.fieldName;
return _c('div',{key:fieldLabel,staticClass:"mb-5"},[_vm._t((columnName + "." + fieldName + ".label"),[_c('div',{staticClass:"grey--text text--darken-1 body-2"},[_vm._v(_vm._s(fieldLabel))])]),_vm._t((columnName + "." + fieldName + ".content"),[(!_vm.isNone(_vm.details[fieldName]))?_c('div',{staticClass:"grey--text text--darken-3 body-1"},[_vm._v(" "+_vm._s(_vm.details[fieldName])+" ")]):_c('div',{staticClass:"grey--text text--lighten-1 font-italic body-1"},[_vm._v("none")])])],2)}),0)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }