<template>
  <detail-page-template v-if="logisticCenter">
    <template v-slot:header>
      <details-page-header :header-info="logisticCenter">
        <template v-slot:actions>
          <dots-dropdown :items="[]" />
        </template>
      </details-page-header>
    </template>

    <template v-slot:content>
      <v-tabs color="blue-grey darken-3" height="30">
        <v-tab class="text-none">Details</v-tab>
        <v-tab class="text-none">Delivery Costs</v-tab>
        <v-tab class="text-none">Products</v-tab>
        <v-tab class="text-none">Orders</v-tab>
        <v-tab class="text-none">Carriers</v-tab>
        <v-tab v-if="isAlmajdouieLogisticCenter" class="text-none">Countries</v-tab>

        <v-tab-item>
          <logistic-center-details-tab
            :logistic-center="logisticCenter"
            @update-logistic-center="updateLogisticCenter"
          />
        </v-tab-item>
        <v-tab-item>
          <delivery-costs-tab
            :logistic-center="logisticCenter"
            @update-countries="updateLogisticCenter"
          />
        </v-tab-item>
        <v-tab-item>
          <products-tab :logistic-center="logisticCenter" />
        </v-tab-item>
        <v-tab-item>
          <orders-tab :logistic-center="logisticCenter" />
        </v-tab-item>
        <v-tab-item>
          <carriers-tab :logistic-center="logisticCenter" />
        </v-tab-item>
        <v-tab-item v-if="isAlmajdouieLogisticCenter">
          <countries-tab :logistic-center="logisticCenter" />
        </v-tab-item>
      </v-tabs>
    </template>
  </detail-page-template>
</template>

<script>
import DetailsPageHeader from '@/components/common/DetailsPageHeader.vue';
import LogisticCenterDetailsTab from '@/components/logistic-center/LogisticCenterDetailsTab.vue';
import DeliveryCostsTab from '@/components/logistic-center/DeliveryCostsTab.vue';
import ProductsTab from '@/components/logistic-center/ProductsTab.vue';
import DetailPageTemplate from '@/components/tempates/DetailPageTemplate.vue';
import DotsDropdown from '@/components/users-page/DotsDropdown.vue';
import OrdersTab from '@/components/logistic-center/OrdersTab.vue';
import CarriersTab from '@/components/logistic-center/CarriersTab.vue';
import CountriesTab from '@/components/logistic-center/CountriesTab.vue';

import { getLogisticCenterById } from '@/api/logisticCenter.api';

import { DELIVERY_FLOW } from '@/constants/regions';

export default {
  name: 'LogisticCenterDetailPage',
  components: {
    OrdersTab,
    ProductsTab,
    LogisticCenterDetailsTab,
    DeliveryCostsTab,
    DetailsPageHeader,
    DotsDropdown,
    DetailPageTemplate,
    CarriersTab,
    CountriesTab
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      logisticCenter: null
    };
  },
  computed: {
    isAlmajdouieLogisticCenter() {
      if (!this.logisticCenter) {
        return false;
      }

      return this.logisticCenter.deliveryFlow === DELIVERY_FLOW.AL_MAJDOUIE;
    }
  },
  mounted() {
    this.updateLogisticCenter();
  },
  methods: {
    async updateLogisticCenter() {
      const { data } = await getLogisticCenterById(this.id);

      this.logisticCenter = data;
    }
  }
};
</script>
