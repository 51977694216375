<template>
  <section class="logistic-center-details pt-10">
    <details-list :details="logisticCenter" :columns="$options.logisticCenterDetailsFieldConfig" />

    <update-logistic-center-info-modal
      :logistic-center="logisticCenter"
      edit-mode
      @create-logistic-center="updateLogisticCenterInfo"
      @update-logistic-center="updateLogisticCenterInfo"
    >
      <template v-slot:activator="{ on }">
        <v-btn tile large outlined color="light-blue darken-4" class="white--text mt-2" v-on="on">
          <v-icon left>mdi-pencil</v-icon> Edit
        </v-btn>
      </template>
    </update-logistic-center-info-modal>

    <v-divider class="mt-7 mb-6" />

    <logistic-center-contacts-info v-if="logisticCenter" :logistic-center-id="logisticCenter.id" />
  </section>
</template>

<script>
import DetailsColumn from '@/models/details-list/DetailsColumn';
import DetailsColumnField from '@/models/details-list/DetailsColumnField';
import DetailsList from '@/components/common/DetailsList.vue';
import LogisticCenterContactsInfo from '@/components/logistic-center/LogisticCenterContactsInfo.vue';
import UpdateLogisticCenterInfoModal from '@/components/logistic-center/UpdateLogisticCenterInfoModal.vue';

const LOGISTIC_CENTER_DETAILS_FIELD_CONFIG = [
  new DetailsColumn({
    columnName: 'generalInfo',
    fields: [new DetailsColumnField({ fieldLabel: 'Name', fieldName: 'name' })]
  })
];

export default {
  name: 'LogisticCenterDetailsTab',
  components: { DetailsList, LogisticCenterContactsInfo, UpdateLogisticCenterInfoModal },
  logisticCenterDetailsFieldConfig: LOGISTIC_CENTER_DETAILS_FIELD_CONFIG,
  props: {
    logisticCenter: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    updateLogisticCenterInfo() {
      this.$emit('update-logistic-center');
    }
  }
};
</script>
