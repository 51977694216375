<template>
  <section class="pt-10">
    <div class="d-flex mb-10">
      <v-btn
        tile
        large
        :loading="isTrackingNumbersFileUploading"
        color="light-blue darken-4"
        class="white--text mr-8"
        @click="selectTrackingNumbersFile"
      >
        Upload tracking number
      </v-btn>
      <v-btn
        tile
        large
        :loading="isInvoicesFileUploading"
        color="light-blue darken-4"
        class="white--text"
        @click="selectInvoicesFile"
      >
        Upload invoices
      </v-btn>
    </div>

    <data-table
      :headers="$options.ordersHeaderConfig"
      :items="logisticCenterOrders"
      :server-items-length="serverItemsLength"
      :options.sync="options"
      :loading="isLoading"
    >
      <template v-slot:item.orderId="{ item }">
        <link-with-icon
          :icon-url="$options.orderIconUrl"
          :link-text="item.orderId"
          :link-to="{ name: 'OrderDetails', params: { id: item.orderId } }"
        />
      </template>

      <template v-slot:item.trackingNumber="{ item }">
        <update-tracking-number-info-modal
          :order="item"
          :carriers="carriers"
          @update-order="getOrdersList"
        >
          <template v-slot:activator="{ on }">
            <span
              v-if="item.trackingNumber"
              class="light-blue--text text--darken-4 font-weight-medium cursor-pointer"
              @click="onOpenModal(on, item)"
            >
              {{ item.trackingNumber }}
            </span>
            <span
              v-else
              class="blue-grey--text text--lighten-2 cursor-pointer"
              @click="onOpenModal(on, item)"
              >n/a</span
            >
          </template>
        </update-tracking-number-info-modal>
      </template>

      <template v-slot:item.trackingNumberRegistrationDate="{ item }">
        <span v-if="item.trackingNumber">{{ item.trackingNumberRegistrationDate }}</span>
        <span v-else class="blue-grey--text text--lighten-2">n/a</span>
      </template>

      <template v-slot:item.carrier="{ item }">
        <span v-if="item.carrier">{{ item.carrier }}</span>
        <span v-else class="blue-grey--text text--lighten-2">n/a</span>
      </template>

      <template v-slot:item.invoiceDownloadLink="{ item }">
        <v-btn
          v-if="item.hasInvoice"
          fab
          x-small
          depressed
          color="grey lighten-2"
          @click="downloadInvoice(item)"
        >
          <v-icon color="blue darken-4" x-small>mdi-arrow-collapse-down</v-icon>
        </v-btn>
        <span v-else class="blue-grey--text text--lighten-2">n/a</span>
      </template>
    </data-table>

    <information-modal
      v-model="isInfoModalVisible"
      width="420"
      :main-content="mainInfoContent"
      :error-content="errorInfoContent"
      @close-modal="closeInfoModal"
    />
  </section>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';
import LinkWithIcon from '@/components/common/LinkWithIcon.vue';
import UpdateTrackingNumberInfoModal from '@/components/logistic-center/UpdateTrackingNumberModal.vue';
import InformationModal from '@/components/common/InformationModal.vue';

import {
  uploadTrackingNumbers,
  uploadInvoices,
  getLogisticCenterOrders,
  downloadOrderInvoice
} from '@/api/logisticCenter.api';
import { getCarriersByOrderId } from '@/api/orders.api';

import {
  formatDateFieldsInArray,
  isPdfFile,
  isXlsxFile,
  transformDataTableOptionsForRequest,
  downloadFile,
  getErrorDescription
} from '@/utils';
import { SORTING_DIRECTIONS } from '@/constants/common';

const ORDERS_HEADER_CONFIG = [
  { text: 'Order Id', value: 'orderId' },
  { text: 'Order created', value: 'purchaseDate' },
  { text: 'Tracking number', value: 'trackingNumber' },
  { text: 'Tracking number added', value: 'trackingNumberRegistrationDate' },
  { text: 'Carrier', value: 'carrier' },
  { text: 'Invoice', value: 'invoiceDownloadLink' }
];

const ORDER_ICON_URL = '/icons/order-icon.svg';
const DEFAULT_SORT_BY_FIELD = 'orderId';

const INFO_MODAL_TEXT = {
  TRACKING_NUMBERS: 'Tracking numbers',
  INVOICES: 'Invoices',
  COMMON_PART: `added.\nEmails sent.`
};

export default {
  name: 'OrdersTab',
  components: { UpdateTrackingNumberInfoModal, InformationModal, DataTable, LinkWithIcon },
  ordersHeaderConfig: ORDERS_HEADER_CONFIG,
  orderIconUrl: ORDER_ICON_URL,
  props: {
    logisticCenter: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isInfoModalVisible: false,
      mainInfoContent: '',
      errorInfoContent: '',
      isLoading: false,
      isTrackingNumbersFileUploading: false,
      isInvoicesFileUploading: false,
      trackingNumbersFileInput: null,
      invoicesFileInput: null,
      logisticCenterOrders: [],
      serverItemsLength: 0,
      options: {},
      carriers: []
    };
  },
  watch: {
    options() {
      this.getOrdersList();
    }
  },
  mounted() {
    this.initFileInputs();
  },
  methods: {
    async getOrdersList() {
      this.isLoading = true;

      const {
        page,
        sortDirection,
        sortType = DEFAULT_SORT_BY_FIELD,
        size
      } = transformDataTableOptionsForRequest(this.options, SORTING_DIRECTIONS.DESC);

      const requestParams = { sortType, sortDirection, page, size };

      try {
        const {
          data: { orders, ordersTotal }
        } = await getLogisticCenterOrders(this.logisticCenter.id, requestParams);

        this.serverItemsLength = ordersTotal;
        this.logisticCenterOrders = formatDateFieldsInArray(orders, [
          'trackingNumberRegistrationDate',
          'purchaseDate'
        ]);
      } catch (error) {
        this.logisticCenterOrders = [];
      } finally {
        this.isLoading = false;
      }
    },
    async getCarriersList(orderId) {
      const { data } = await getCarriersByOrderId(orderId);

      this.carriers = data;
    },
    selectTrackingNumbersFile() {
      this.trackingNumbersFileInput.click();
    },
    selectInvoicesFile() {
      this.invoicesFileInput.click();
    },
    initFileInputs() {
      const trackingNumbersFileInput = document.createElement('input');
      const invoicesFileInput = document.createElement('input');

      trackingNumbersFileInput.type = 'file';
      trackingNumbersFileInput.addEventListener('change', ({ target: { files } }) => {
        this.uploadTrackingNumbersFiles(files);
      });

      invoicesFileInput.type = 'file';
      invoicesFileInput.multiple = true;
      invoicesFileInput.addEventListener('change', ({ target: { files } }) => {
        this.uploadInvoicesFiles(files);
      });

      this.trackingNumbersFileInput = trackingNumbersFileInput;
      this.invoicesFileInput = invoicesFileInput;
    },
    async uploadTrackingNumbersFiles(files) {
      if (!files || files.length === 0) {
        return;
      }

      const [file] = files;

      if (!isXlsxFile(file)) {
        return;
      }

      this.isTrackingNumbersFileUploading = true;

      try {
        const { data: uploadErrors } = await this.uploadTrackingNumbers(file);

        this.isTrackingNumbersFileUploading = false;
        this.mainInfoContent = `${INFO_MODAL_TEXT.TRACKING_NUMBERS} ${INFO_MODAL_TEXT.COMMON_PART}`;
        this.errorInfoContent = this.transformUploadErrors(uploadErrors);
        this.isInfoModalVisible = true;
      } catch (error) {
        this.mainInfoContent = getErrorDescription(error);
        this.isTrackingNumbersFileUploading = false;
        this.isInfoModalVisible = true;
      }

      await this.getOrdersList();
      this.resetTrackingNumbersFileInput();
    },
    resetTrackingNumbersFileInput() {
      this.trackingNumbersFileInput.value = null;
    },
    async uploadInvoicesFiles(files) {
      if (!files || files.length === 0) {
        return;
      }

      const hasNotPdfFiles = Array.prototype.some.call(files, file => !isPdfFile(file));

      if (hasNotPdfFiles) {
        this.mainInfoContent = 'Please, select only PDF files';
        this.errorInfoContent = '';
        this.isInfoModalVisible = true;

        return;
      }

      this.isInvoicesFileUploading = true;

      try {
        const { data: uploadErrors } = await this.uploadInvoices(files);

        this.isInvoicesFileUploading = false;
        this.mainInfoContent = `${INFO_MODAL_TEXT.INVOICES} ${INFO_MODAL_TEXT.COMMON_PART}`;
        this.errorInfoContent = this.transformUploadErrors(uploadErrors);
        this.isInfoModalVisible = true;
      } catch (error) {
        this.isInvoicesFileUploading = false;
        this.mainInfoContent = getErrorDescription(error);
        this.isInfoModalVisible = true;
      }

      await this.getOrdersList();
    },
    transformUploadErrors(uploadErrors) {
      const errorNumber = Object.keys(uploadErrors).length;

      if (!errorNumber) {
        return '';
      }

      const errorsHeaderText = `${errorNumber} errors detected:\n`;

      return Object.entries(uploadErrors).reduce((errorsText, [rowNumber, rowError]) => {
        return `${errorsText}\n${rowNumber} - ${rowError}`;
      }, errorsHeaderText);
    },
    uploadTrackingNumbers(file) {
      return uploadTrackingNumbers(file);
    },
    uploadInvoices(files) {
      return uploadInvoices(files);
    },
    async downloadInvoice({ orderId }) {
      const { data } = await downloadOrderInvoice(orderId);

      if (!data) {
        return;
      }

      const invoiceFileName = `${orderId}_inv.pdf`;

      downloadFile(data, invoiceFileName);
    },
    closeInfoModal() {
      this.isInfoModalVisible = false;
    },
    onOpenModal({ click }, { orderId }) {
      click();
      this.getCarriersList(orderId);
    }
  }
};
</script>
