<template>
  <update-info-modal
    v-model="isTrackingNumberModalShown"
    width="420"
    action-button-label="Save"
    :loading="isLoading"
    modal-title="Edit"
    :action-button-color="actionButtonColor"
    @close-modal="onModalClose"
    @action-button-click="onActionButtonClick"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <template v-slot:modal-body>
      <v-form ref="trackingNumberInfoForm" :key="isTrackingNumberModalShown" class="pt-1">
        <base-text-field
          v-model="orderInfo.trackingNumber"
          label="Tracking number"
          :rules="$options.validationFieldRequiredRule"
          class="tracking-info__number mb-5"
        />

        <base-select
          v-model="selectedCarrier.carrier"
          label="Carrier"
          :items="availableCarriers"
          :rules="$options.validationFieldRequiredRule"
          class="tracking-info__carrier mb-5"
          @input="updateCarriesInputField"
        />

        <v-checkbox
          v-model="selectedCarrier.sendNotification"
          :disabled="!hasSendNotifications"
          label="Send notification"
          hide-details="auto"
          @change="updateInputField($event, 'sendNotification')"
        />
      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import { clone, pathOr } from 'ramda';

import BaseTextField from '@/components/common/BaseTextField.vue';
import BaseSelect from '@/components/common/BaseSelect.vue';
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';

import { updateOrder } from '@/api/orders.api';

import { DEFAULT_BUTTON_COLOR, ERROR_BUTTON_COLOR, VALIDATION_RULES } from '@/constants/common';

export default {
  name: 'UpdateTrackingNumberModal',
  components: { BaseTextField, UpdateInfoModal, BaseSelect },
  validationFieldRequiredRule: [VALIDATION_RULES.REQUIRED],
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    carriers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      orderInfo: {},
      actionButtonColor: DEFAULT_BUTTON_COLOR,
      isTrackingNumberModalShown: false,
      isLoading: false,
      selectedCarrier: { carrier: null, sendNotification: false }
    };
  },
  computed: {
    availableCarriers() {
      return this.carriers.map(({ name }) => ({
        text: name,
        value: name
      }));
    },

    hasSendNotifications() {
      if (!this.carriers.length) {
        return false;
      }

      const selectedCarrier = this.carriers.find(
        ({ name }) => name === this.selectedCarrier.carrier
      );

      return pathOr(false, ['sendNotifications'], selectedCarrier);
    }
  },
  watch: {
    order: {
      handler: 'getOrderInfo',
      immediate: true
    }
  },
  methods: {
    onModalClose() {
      this.isTrackingNumberModalShown = false;

      if (this.order.trackingNumber !== this.orderInfo.trackingNumber) {
        this.getOrderInfo();
      }
    },
    getOrderInfo() {
      this.orderInfo = clone(this.order);
      this.selectedCarrier.carrier = this.orderInfo.carrier;
    },
    async onActionButtonClick() {
      if (!this.$refs.trackingNumberInfoForm.validate()) {
        return;
      }

      this.isLoading = true;
      this.actionButtonColor = DEFAULT_BUTTON_COLOR;

      try {
        const { orderId, trackingNumber } = this.orderInfo;

        const updatedOrder = {
          ...this.selectedCarrier,
          trackingNumber
        };

        await updateOrder(orderId, updatedOrder);

        this.$emit('update-order');
        this.onModalClose();
      } catch (error) {
        this.actionButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    },
    updateCarriesInputField(value) {
      this.selectedCarrier.sendNotification = false;
      this.updateInputField(value, 'carrier');
    },
    updateInputField(value, fieldName) {
      this.selectedCarrier[fieldName] = value;
    }
  }
};
</script>
