<template>
<update-info-modal
    v-model="isCountryModalShown"
    width="640"
    scrollable
    action-button-label="Save"
    :loading="isLoading"
    :modal-title="regionInfoModalTitle"
    :action-button-color="actionButtonColor"
    @close-modal="onModalClose"
    @action-button-click="onActionButtonClick"
    @cancel="onModalClose"
  >
    <template v-slot:activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>
    <template v-slot:modal-body>
     
      <v-form ref="countryInfoForm" :key="isCountryModalShown" class="pt-7 pr-4 pb-5 pl-1">
        <base-select
          v-model="country.name"
          :items="countryList"
          item-text="name"
          item-value="name"
          multiple
          return-object
          label="Select"
        />
        <v-divider class="mb-5" />
        <base-text-field v-model="country.vat" class="mb-5" label="VAT , %" />

        <v-label>Total Costs (w/serum)</v-label> 
        <base-text-field v-model="country.classicDeliveryCostWithSerum" class="mb-5" label="Classic , €" />
        <base-text-field v-model="country.expressDeliveryCostWithSerum" class="mb-5" label="Express , €" />

        <v-label>Total Costs (no serum)</v-label> 
        <base-text-field v-model="country.classicDeliveryCostNoSerum" class="mb-5" label="Classic , €" />
        <base-text-field v-model="country.expressDeliveryCostNoSerum" class="mb-5" label="Express , €" />

        <v-label>Delivery time</v-label> 
        <base-text-field v-model="country.classicDeliveryDays" class="mb-5" label="Classic , days" />
        <base-text-field v-model="country.expressDeliveryDays" class="mb-5" label="Express , days" />


      </v-form>
    </template>
  </update-info-modal>
</template>

<script>
import BaseSelect from '@/components/common/BaseSelect.vue';

import BaseTextField from '@/components/common/BaseTextField.vue';
import UpdateInfoModal from '@/components/common/UpdateInfoModal.vue';

import { updateLogisticCenter } from '@/api/logisticCenter.api';

import { DEFAULT_BUTTON_COLOR, ERROR_BUTTON_COLOR } from '@/constants/common';






export default {
  name: 'UpdateRegionInfoModal',
  components: { BaseSelect, UpdateInfoModal , BaseTextField},
  props: {
    logisticCenter: {
      type: Object,
      default: () => ({})
    },
    availableCountries: {
      type: Array,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    text1: {
      type: String,
      default: 'Total costs( (w/serum)'
    },
    country: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedCountries: [],
      actionButtonColor: DEFAULT_BUTTON_COLOR,
      isCountryModalShown: false,
      isLoading: false
    };
  },
  computed: {
    regionInfoModalTitle() {
       const titlePrefix = this.editMode ? 'Edit' : 'Add';
       return `${titlePrefix} Country`;
    },
    countryList(){
      return [this.country];
    }
  },
  methods: {
    onModalClose() {
      this.isCountryModalShown = false;
      this.selectedCountries = [];
    },
    async onActionButtonClick() {
      this.isLoading = true;
      this.actionButtonColor = DEFAULT_BUTTON_COLOR;

      const regions = [...this.logisticCenter.regions, ...this.selectedCountries];

      if (this.editMode) {
         this.country.country = this.country.countryCode;
          delete this.country.id;
          delete this.country.phoneIso;
          const tempName =  this.country.name;; 
          delete this.country.name;
          delete this.country.countryCode;
          await updateLogisticCenter(this.logisticCenter.id, this.country);
          this.country.name = tempName;
          this.onModalClose();
          this.$emit('update-region');
          return;
        }

      try {
        
        await updateLogisticCenter({ ...this.logisticCenter, regions });
        this.$emit('update-region');
        this.onModalClose();
      } catch (error) {
        this.actionButtonColor = ERROR_BUTTON_COLOR;
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/user/update-user-modal';
</style>
