var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pt-10"},[_c('div',{staticClass:"d-flex mb-10"},[_c('v-btn',{staticClass:"white--text mr-8",attrs:{"tile":"","large":"","loading":_vm.isTrackingNumbersFileUploading,"color":"light-blue darken-4"},on:{"click":_vm.selectTrackingNumbersFile}},[_vm._v(" Upload tracking number ")]),_c('v-btn',{staticClass:"white--text",attrs:{"tile":"","large":"","loading":_vm.isInvoicesFileUploading,"color":"light-blue darken-4"},on:{"click":_vm.selectInvoicesFile}},[_vm._v(" Upload invoices ")])],1),_c('data-table',{attrs:{"headers":_vm.$options.ordersHeaderConfig,"items":_vm.logisticCenterOrders,"server-items-length":_vm.serverItemsLength,"options":_vm.options,"loading":_vm.isLoading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_c('link-with-icon',{attrs:{"icon-url":_vm.$options.orderIconUrl,"link-text":item.orderId,"link-to":{ name: 'OrderDetails', params: { id: item.orderId } }}})]}},{key:"item.trackingNumber",fn:function(ref){
var item = ref.item;
return [_c('update-tracking-number-info-modal',{attrs:{"order":item,"carriers":_vm.carriers},on:{"update-order":_vm.getOrdersList},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.trackingNumber)?_c('span',{staticClass:"light-blue--text text--darken-4 font-weight-medium cursor-pointer",on:{"click":function($event){return _vm.onOpenModal(on, item)}}},[_vm._v(" "+_vm._s(item.trackingNumber)+" ")]):_c('span',{staticClass:"blue-grey--text text--lighten-2 cursor-pointer",on:{"click":function($event){return _vm.onOpenModal(on, item)}}},[_vm._v("n/a")])]}}],null,true)})]}},{key:"item.trackingNumberRegistrationDate",fn:function(ref){
var item = ref.item;
return [(item.trackingNumber)?_c('span',[_vm._v(_vm._s(item.trackingNumberRegistrationDate))]):_c('span',{staticClass:"blue-grey--text text--lighten-2"},[_vm._v("n/a")])]}},{key:"item.carrier",fn:function(ref){
var item = ref.item;
return [(item.carrier)?_c('span',[_vm._v(_vm._s(item.carrier))]):_c('span',{staticClass:"blue-grey--text text--lighten-2"},[_vm._v("n/a")])]}},{key:"item.invoiceDownloadLink",fn:function(ref){
var item = ref.item;
return [(item.hasInvoice)?_c('v-btn',{attrs:{"fab":"","x-small":"","depressed":"","color":"grey lighten-2"},on:{"click":function($event){return _vm.downloadInvoice(item)}}},[_c('v-icon',{attrs:{"color":"blue darken-4","x-small":""}},[_vm._v("mdi-arrow-collapse-down")])],1):_c('span',{staticClass:"blue-grey--text text--lighten-2"},[_vm._v("n/a")])]}}])}),_c('information-modal',{attrs:{"width":"420","main-content":_vm.mainInfoContent,"error-content":_vm.errorInfoContent},on:{"close-modal":_vm.closeInfoModal},model:{value:(_vm.isInfoModalVisible),callback:function ($$v) {_vm.isInfoModalVisible=$$v},expression:"isInfoModalVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }